<template>
  <div class="reception-container">
    <template v-if="pageShow">
      <van-sticky>
        <head-bar type="primary" :leftArrow="false" :left-text="false" title="防疫须知" @onClickLeft="onClickLeft" />
      </van-sticky>
      <div class="head-banner">
        <Banner :active="false" />
        <div class="title-wraper">
          <div class="title">2023互联网岳麓峰会线上防疫须知</div>
          <div class="text">为保障您与他人的身体健康，请您熟知以下防疫要求：</div>
        </div>
      </div>


      <div class="basic-wraper">
        <div class="basic-title">
          <van-divider content-position="left" :hairline="false">
            <div class="title-text">正文：</div>
          </van-divider>
        </div>
        <!-- <div class="normal">为保障您与他人的身体健康，请您熟知以下防疫要求：</div> -->
        <div class="narmal">1、如有以下情况的，不得参加本次会议：</div>
        <p class="normal">①大会前14 天内有国(境)外或港台旅居史的;</p>
        <p class="normal">
          ②大会前10 天内有海南、西藏、新疆(含兵团)、青海、陕西、重庆全域等疫情严重地旅居史的;近7天内有外溢风险较大的地区(江西鹰潭，河南三门峡、信阳，广东深圳南山区、宝安区、盐田区、佛山禅城区、广州越秀区，甘肃庆阳，湖北襄阳，福建泉州晋江，四川阿坝州、南充，黑龙江齐齐哈尔、大庆，安徽安庆，云南昆明盘龙区，辽宁大连沙河口区，内蒙古阿拉善、二连浩特，浙江宁波鄞州区，吉林珲春，广西防城港)旅居史的; 近7天内有高、中风险地区所在县(县级市、区、自治县、旗、自治旗等)旅居史的;
        </p>
        <p class="normal">③有发热、干咳、乏力、咽痛、鼻塞、流涕、结膜炎、肌肉酸痛、味嗅觉减退或丧失、腹泻等新冠肺炎相关可疑症状且不能排除传染病;</p>
        <p class="normal">④湖南居民健康码为红码或黄码的;</p>
        <p class="normal">⑤正处隔离治疗、隔离医学观察、居家健康监测的;或与新冠病例有时空交集的;</p>
        <p class="normal">
          ⑥报到时不能提供48小时内核酸检测阴性证明的(省外常态化防控区旅居史人员无抵长后“三天两检”核酸阴性证明);
        </p>
        <p class="normal">⑦高风险岗位从业人员脱离岗位后，未完成7天集中或居家隔离者;</p>
        <p class="normal">⑧工作人员不能提供大会前3天2次核酸阴性证明的(工作人员会议期间须每日1 次核酸检测);</p>
        <p class="normal">⑨经防疫部门评估，其他情况不适合参会的。</p>
        <p class="normal">2、核酸检测</p>
        <p class="normal sub-title">
          <span class="weight">会前：</span>所有参会人员及工作人员报到时须做一次核酸检测(当天在“三站一场”已开展了落地核酸检测的不需重复做)。
        </p>
        <p class="normal sub-title">
          <span class="weight">会期：</span>省外或近7天内有省外旅居史参会人员会议期间必须按照组委会安排定时定点每日开展1次核酸。省内参会人员抵长后就近原则进行1次落地核酸检测，建议后续活动期间核酸每日1检。各酒店、会场工作人员每日开展一次核酸检测。
        </p>
        <p class="normal sub-title">组委会也将在峰会定点酒店及会场外每天设置免费核酸采样点为嘉宾提供服务，会议期间必须按照组委会安排每日开展1次核酸，持24小时核酸检测阴性证明方可进入会场。
          注:核酸检测前48小时不能接种新冠疫苗。</p>
        <div class="tips">注:核酸检测前48小时不能接种新冠疫苗。</div>
        <p class="normal">3、温馨提示</p>
        <p class="normal">①如遇健康码、行程码排查有异常的，报当地疫情防控指挥部甄别判断。</p>
        <p class="normal">②会议期间请您全程规范佩戴口罩，废弃口罩请勿随意丢弃，需投入废弃口罩专用垃圾桶。</p>
        <p class="normal">③会议期间请注意保持1米以上间距，会议结束后注意有序离场切勿扎堆。</p>
        <p class="normal">④参会人员和工作人员均实行闭环管理，不得参加非组委会安排的其他聚集性活动和前往人员密集的场所。</p>
        <p class="normal">⑤所有来长参会人员途中乘坐公共交通工具须全程规范佩 戴好口罩，注意手卫生、保持社交距离等个人防护，乘坐公共交 通工具期间尽量避免因进食等原因出现摘脱口罩的情况。</p>
      </div>

    
    </template>

    <Map v-else />

    <float-menu />
  </div>
</template>

<script>
import Banner from "@/components/banner/index";
import floatMenu from "@/components/floatMenu/index";
import headBar from "@/components/headBar/headBar";
export default {
  name: "reception",
  components: { floatMenu, Banner,  headBar },
  data() {
    return {
      pageShow: true
    };
  },
  methods: {
    handleGoMap() {
      this.$router.push("/map/" + this.$store.state.miceInfo.miceLink);
    },
    onClickLeft() {
      this.$router.push("/index/" + this.$store.state.miceInfo.miceLink);
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.van-divider--content-left::before {
  max-width: 0;
  margin: 0;
}
.reception-container {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgb(242, 248, 252);
  .basic-wraper {
    padding: 15px;
    background-color: #fff;
    margin-bottom: 10px;
    color: #666;
    font-size: 12px;
    p {
      margin: 5px 0 15px 0;
      line-height: 1.9;
    }
    p.normal {
      margin: 0;
    }
    .tips-title {
      font-weight: bolder;
    }
    .tips {
      color: rgb(226, 147, 44);
    }
    .normal {
      line-height: 2;
    }
    .basic-title {
      margin-bottom: 10px;
      .van-divider {
        margin: 0;
        color: #333;
        font-size: 14px;
        font-weight: bold;
      }
      .title-text {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          height: 20px;
          width: 20px;
          margin-right: 5px;
          background-image: url("~@/assets/images/reception/title-icon.png");
          background-size: 100% 100%;
          background-position: center;
        }
      }
    }
  }
  .head-banner {
    position: relative;
    margin-bottom: 76px;
    .title-wraper {
      position: absolute;
      width: 100%;
      bottom: -35px;
      background-color: #fff;
      padding: 20px;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      font-size: 13px;
      color: #333;
      .title {
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
        width: 100%;
        text-align: center;
      }
      .text {
        text-indent: 28px;
        line-height: 1.8;
      }
    }
  }
  .traffic {
    .map-container {
      width: 100%;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
      background: #fff;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      img {
        height: 110px;
        width: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
      .text-wraper {
        width: 100%;
        height: 40px;
        display: flex;
        // flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        span:nth-of-type(1) {
          display: flex;
          padding: 5px 10px;
          background: #06c671;
          color: #fff;
          font-size: 12px;
          border-radius: 4px;
          align-items: center;
          img {
            margin-left: 5px;
            // height: 13px;
            height: auto;
            width: 13px;
          }
        }
      }
    }
    .fir-title {
      color: #3c97ed;
      font-size: 14px;
      margin: 15px 0;
    }
    .sec-title {
      font-weight: bold;
      font-size: 13px;
      color: #333;
    }
  }
  .official {
    .qr-code {
      display: flex;
      // margin-top: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f2f8fc;
      padding: 15px 0;
      img {
        height: 100px;
        width: 100px;
        margin-bottom: 10px;
      }
    }
  }
}
.sub-title {
  text-indent: 2em;
  .weight {
    font-weight: bolder;
  }
}
</style>
